
import { Component, Vue } from 'vue-property-decorator';
import { getIssueNewsDetailsApi } from '@/api/news'
import { UserModule } from '@/store/modules/user'
import { checkImg, isImage } from '@/utils/function_utils';
@Component({
  name: 'newsInfoDetail',
})
export default class extends Vue {

  get publicFilesUrl() {
    return UserModule.publicFilesUrl;
  }
  // 附件是否是图片
  get isImage() {
    return (data: any) => isImage(data);
  }
  // 浏览图片
  get getImage() {
    return (data: any) => checkImg(data.fileKey, data.fileNameOrc)
  }
  public newsDetailData: any = {};
  public publishTime: string = ''; // 发布时间

  public mounted() {
    this.getNewsDetail();
  }
  // 获取详情
  public getNewsDetail() {
    const id = +this.$route.params.id;
    const isRead = Boolean(this.$route.query.isRead);
    getIssueNewsDetailsApi(id, isRead).then((res: any) => {
      this.newsDetailData = res.data.data;
      if (this.newsDetailData.publishTime) {
        this.publishTime = this.newsDetailData.publishTime
      } else {
        if (this.newsDetailData.gmtCreate) {
          this.publishTime = this.newsDetailData.gmtCreate.split(" ")[0];
        }
      }
    })
  }
  // 返回新闻资讯列表
  public handleReturnList() {
    this.$router.push({
      name: 'newsInfoList',
      query: {
        type: 'return'
      }
    })
  }
}
